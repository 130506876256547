import { clearCart } from "../../../store/cart/cart.action";
import { Link } from "react-router-dom";
import { BUTTON_TYPE_CLASSES } from "../../../components/button/button.component";
import { selectCartItems } from "../../../store/cart/cart.selector";

import { FaCheckCircle } from "react-icons/fa";

import { SuccessContainer, ButtonSuccess } from "./success.styles";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PaymentCompleted } from "../../../utils/Cookies/facebookPixelEvents";

const Success = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  useEffect(() => {
    PaymentCompleted(20);
    if (cartItems.length !== 0) {
      dispatch(clearCart());
    }
  }, [cartItems, dispatch]);

  return (
    <SuccessContainer>
      <FaCheckCircle style={{ color: "green", fontSize: "xxx-large" }} />
      <h1>Thank you for your order</h1>
      <p>
        We are currently processing your order and will send you a confirmation
        email shortly
      </p>
      <div>
        <Link to="/shop">
          <ButtonSuccess buttonType={BUTTON_TYPE_CLASSES.base}>
            Continue Shopping
          </ButtonSuccess>
        </Link>
      </div>
    </SuccessContainer>
  );
};

export default Success;
