import "../Privacy-Policies/PrivacyPolicies.styles.scss";

const PrivacyPolicies = () => {
  return (
    <div className="container-PrivacyPolicies">
      <h2>Política de Privacidade e Utilização de Cookies</h2>
      <p>
        Esta Política de Privacidade descreve como recolhemos, utilizamos,
        partilhamos e protegemos as suas informações pessoais quando visita o
        nosso site e interage com os nossos serviços. Ao aceder a emestrong.com,
        poderá ou não concorda com os termos e práticas descritas nesta Política
        de Privacidade.
      </p>
      <h3>Consentimento de Cookies</h3>
      <p>
        O uso de cookies de pixel e outras cookies é ativado somente após o seu
        consentimento. Quando visita o nosso site, apresentamos um aviso de
        cookies e uma opção para aceitar ou recusar a utilização de cookies. A
        cookie "userConsent" regista o seu consentimento e é válida por um mês.
        Após esse período, será solicitado novamente o seu consentimento.
      </p>
      <h3>Utilização de Cookies de Pixel</h3>
      <p>
        Utilizamos cookies de pixel, incluindo aqueles fornecidos pelo Facebook,
        para:
      </p>
      <ul>
        <li>Acompanhar o desempenho de anúncios publicitários.</li>
        <li>
          Fornecer anúncios segmentados com base nas suas atividades no nosso
          site.
        </li>
        <li>Avaliar a eficácia das nossas campanhas publicitárias.</li>
        <li>Melhorar e personalizar a sua experiência de compra.</li>
      </ul>
      <h3>Partilha de Informações</h3>
      <p>
        Partilhamos informações pessoais com terceiros, incluindo o Facebook,
        para fins de marketing e publicidade direcionada. Garantimos que todas
        as partes envolvidas adotem medidas de segurança adequadas para proteger
        as suas informações.
      </p>
      <h3>Contacto</h3>
      <p>
        Se tiver alguma dúvida sobre esta Política de Privacidade, as suas
        informações pessoais ou as nossas práticas de privacidade, contacte-nos
        em <a href="mailto:support@emestrong.com">support@emestrong.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicies;
