import React from "react";
import "./userInfo.styles.scss";

const UserInfo = ({ currentUser }) => {
  return (
    <div className="user-info">
      <div>
        <strong>Name:</strong> {currentUser.displayName}
      </div>
      <div>
        <strong>Email:</strong> {currentUser.email}
      </div>
    </div>
  );
};

export default UserInfo;
