import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";

import { selectCurrentUser } from "../../store/user/user.selector";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import Spinner from "../../components/spinner/spinner.component";
import OrderItem from "../../components/orders/order-item/orderItem.component";
import UserInfo from "../../components/orders/user-info/userInfo.component";
import "./userOrders.styles.scss";

const UserOrders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector(selectCurrentUser);
  const categoriesMap = useSelector(selectCategoriesMap);

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const OrdersCollection = collection(db, "orders");
        const q = query(
          OrdersCollection,
          where("userId", "==", currentUser.id)
        );
        const OrdersSnapshot = await getDocs(q);
        const orders = OrdersSnapshot.docs.map((doc) => doc.data());

        orders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        setUserOrders(orders);
      } catch (error) {
        console.error("Error fetching user orders", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserOrders();
  }, [currentUser.id]);

  const getProductName = (productId) => {
    const product = categoriesMap["all"].find((item) => item.id === productId);
    return product ? product.name : "Product not found";
  };

  const getProductImage = (productId, productColor) => {
    const product = categoriesMap["all"].find((item) => item.id === productId);
    if (!product) return null;

    const colorInfo = product.colors.find(
      (color) => color.color === productColor
    );
    return colorInfo ? colorInfo.images[0].imageLink : null;
  };

  return (
    <div className="container-orders">
      <h2>My Orders</h2>
      <UserInfo currentUser={currentUser} />

      {isLoading ? (
        <Spinner />
      ) : userOrders.length ? (
        <div className="orders-list">
          {userOrders.map((order, index) => (
            <OrderItem
              key={index}
              order={order}
              index={index}
              getProductImage={getProductImage}
              getProductName={getProductName}
            />
          ))}
        </div>
      ) : (
        <div className="no-orders">
          <span>You do not have any orders yet.</span>
        </div>
      )}

      <div className="orders-advice">
        <span>
          <strong>Notice:</strong> If you have any questions about your orders,
          please contact us at:{" "}
          <a href="mailto:support@emestrong.com">support@emestrong.com</a>
        </span>
      </div>
    </div>
  );
};

export default UserOrders;
