import { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import ProductCard from "../product-card/product-card.component";

import "./bonus-products.styles.scss";
// Importe sua configuração do Firebase aqui
import { db, getBonusProducts } from "../../utils/firebase/firebase.utils";
import Spinner from "../spinner/spinner.component";
import BonusProductCard from "../bonus-product-card/bonus-product-card.component";

const BonusProducts = ({ products }) => {
  const [bonusProductsData, setBonusProductsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBonusProducts = async () => {
      try {
        const bonusProductsData = await getBonusProducts();
        //console.log("Produtos Bonus:", bonusProductsData);

        setBonusProductsData(bonusProductsData);
        setLoading(false);

        // Agora, bonusProductsData foi atualizado, e você pode verificar seu comprimento
        //console.log(bonusProductsData.items.length > 0);
      } catch (error) {
        console.error("Erro ao buscar os produtos bonus:", error);
      }
    };

    fetchBonusProducts();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {bonusProductsData.items.length > 0 && (
        <>
          <div className="top-bar-bonus-products">
            <h1>Bonus products</h1>
          </div>
          <div className="bonus-container">
            {products &&
              products
                .filter((product) =>
                  bonusProductsData.items
                    .map((item) => item.id)
                    .includes(product.id)
                )
                .map((product) => (
                  <BonusProductCard key={product.id} product={product} />
                ))}
          </div>
        </>
      )}
    </>
  );
};

export default BonusProducts;
