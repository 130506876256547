import React, { useEffect, useState } from "react";
import "./Notification.scss";

import { AiOutlineCheckCircle } from "react-icons/ai"; // Certifique-se de importar o ícone corretamente

const Notification = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000); // Define o tempo de exibição da notificação para 20 segundos (20000 milissegundos)

    return () => {
      clearTimeout(timer); // Limpa o timer ao desmontar o componente
    };
  }, []);

  return (
    <>
      {visible && (
        <div className="notification">
          {message} <AiOutlineCheckCircle />
        </div>
      )}
    </>
  );
};

export default Notification;
