import { useNavigate } from "react-router-dom";

import {
  ButtonsContainer,
  ButtonStyle1,
  ButtonStyle2,
  SpanText,
} from "./home-buttons.styles";

const HomeButtons = () => {
  const navigate = useNavigate();
  return (
    <ButtonsContainer>
      <ButtonStyle1
        onClick={() => {
          navigate("shop/women");
        }}
      >
        <SpanText>Women</SpanText>
      </ButtonStyle1>
      <ButtonStyle2
        onClick={() => {
          navigate("shop/men");
        }}
      >
        <SpanText>Men</SpanText>
      </ButtonStyle2>
    </ButtonsContainer>
  );
};

export default HomeButtons;
