import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CartItemContainer,
  ItemDetails,
  RemoveButtonDropdown,
} from "./cart-item.styles";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import { selectCartItems } from "../../store/cart/cart.selector";
import { clearCart, clearItemFromCart } from "../../store/cart/cart.action";

const CartItem = ({ cartItem }) => {
  const {
    name,
    colors,
    price,
    quantity,
    selectedSize,
    selectedColorId,
    newPrice,
  } = cartItem;

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  const dispatch = useDispatch();
  const categoriesMap = useSelector(selectCategoriesMap);
  const cartItems = useSelector(selectCartItems);
  const productsToShow = categoriesMap["all"];

  function areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    // Função para buscar o status do desconto na Firebase
    async function checkDiscountStatus() {
      try {
        if (productsToShow && productsToShow.length > 0) {
          const product = productsToShow.find(
            (product) => product.id === cartItem.id
          );

          if (product) {
            const productDiscount = product.colors[selectedColorId].discount;

            if (
              cartItem.colors[selectedColorId].discount !== productDiscount ||
              cartItem.price !== product.price ||
              !areObjectsEqual(
                cartItem.colors[selectedColorId].tamanhos,
                product.colors[selectedColorId].tamanhos
              )
            ) {
              dispatch(clearCart());
            }
          }
        }
      } catch (error) {
        console.error("Erro ao buscar o desconto na Firebase:", error);
      }
    }

    checkDiscountStatus();
  }, [cartItem, selectedColorId, dispatch, productsToShow]);

  const clearItemHandler = () => {
    dispatch(clearItemFromCart(cartItems, cartItem));
  };

  return (
    <CartItemContainer>
      <img src={colors[selectedColorId].images[0].imageLink} alt={`${name}`} />
      <ItemDetails>
        <span>
          {name} ({selectedSize})
        </span>
        {newPrice ? (
          <span>
            {quantity} x {formatCurrency(newPrice)}€
          </span>
        ) : (
          <span>
            {quantity} x {formatCurrency(price)}€
          </span>
        )}
      </ItemDetails>
      <RemoveButtonDropdown onClick={clearItemHandler}>
        &#10005;
      </RemoveButtonDropdown>
    </CartItemContainer>
  );
};

export default CartItem;
