import styled from "styled-components";

export const ProductSearchContainer = styled.div`
  position: relative;

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }

  svg {
    cursor: pointer;
    font-size: larger;
    margin-top: 2px;
  }

  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;

    .product-card {
      padding: 10px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .suggestions {
      max-height: 80vh;
      top: unset;
    }
    width: -webkit-fill-available;
  }

  @media screen and (max-width: 650px) {
    width: unset;
    .suggestions {
      max-height: 200px;
      top: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    width: -webkit-fill-available;
    .suggestions {
      max-height: 80vh;
      top: unset;
    }
  }
`;

export const ProductCardSearch = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  img {
    width: 30%;
    object-fit: cover;
  }

  span {
    font-size: 16px;
  }
`;
