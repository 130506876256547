import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";

import ProductCard from "../../components/product-card/product-card.component";
import Spinner from "../../components/spinner/spinner.component";
import PageNotFound from "../PageNotFound/PageNotFound.component";

import {
  selectCategoriesMap,
  selectCategoriesIsLoading,
} from "../../store/categories/category.selector";

import "./category.styles.scss";

const Category = () => {
  const { category } = useParams();
  const categoriesMap = useSelector(selectCategoriesMap);
  const isLoading = useSelector(selectCategoriesIsLoading);
  const [products, setProducts] = useState(categoriesMap[Category]);

  useEffect(() => {
    setProducts(categoriesMap[category]);
  }, [category, categoriesMap]);

  const categoryExists = !!categoriesMap[category];

  return (
    <Fragment>
      {categoryExists ? ( // Verifique se a categoria existe
        <>
          <h2 className="category-title">{category.toUpperCase()}</h2>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="category-container">
              {products &&
                products.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))}
            </div>
          )}
        </>
      ) : (
        <PageNotFound />
      )}
    </Fragment>
  );
};

export default Category;
