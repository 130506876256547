import styled from "styled-components";

import {
  BaseButton,
  GoogleSignInButton,
  InvertedButton,
} from "../../../components/button/button.styles";

export const SuccessContainer = styled.div`
  margin-top: 160px;
  text-align: center;
`;

export const ButtonSuccess = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: montserrat;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 370px) {
    font-size: 12px;
  }
  @media screen and (max-width: 347px) {
    font-size: 10px;
  }
`;
