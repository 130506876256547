import styled from "styled-components";

export const ButtonCheckout = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-family: montserrat;
  font-weight: bolder;
  border: 1px solid black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgb(233, 233, 233);
  }

  @media screen and (max-width: 370px) {
    font-size: 12px;
  }
  @media screen and (max-width: 347px) {
    font-size: 10px;
  }
`;

export const WarningText = styled.span`
  font-size: smaller;
  margin-top: 5px;
  color: dimgrey;
  text-align: center;
  display: grid;
`;

export const LockIconWrapper = styled.div`
  margin-left: 8px; /* Adjust this margin as needed to control spacing */
  display: flex;
  color: black;
`;

export const LockIconWrapperOpen = styled.div`
  margin-left: 8px; /* Adjust this margin as needed to control spacing */
  display: flex;
  color: black;
`;

export const WarningMessage = styled.div`
  color: red;
  margin-top: 10px;
  margin-bottom: 3px;
  font-size: 14px;
  text-align: center;
`;

export const FormButton = styled.form`
  display: flex;
  justify-content: center;
`;
