import "../Return&RefundPolicy/Return&RefundPolicy.styles.scss";

const ReturnRefundPolicy = () => {
  return (
    <div className="container-ReturnRefundPolicy">
      <h3>Política de devoluções e reembolsos:</h3>
      <p>
        Tem o direito de cancelar o seu pedido dentro de 30 dias, sem fornecer
        nenhum motivo para fazê-lo.
        <br />
        O prazo para cancelar uma encomenda é de 30 dias a partir da data de
        receção da mercadoria ou em que um terceiro por si designado, que não
        seja o transportador, se apodere da mercadoria entregue.
        <br />
        Para exercer o seu direito de cancelamento, deverá comunicar-nos a sua
        decisão através de uma declaração clara.
        <br />
        Pode-nos informar da sua decisão por email support@emestrong.com
        <br />
        Nós o reembolsaremos em até 30 dias a partir do dia em que recebermos os
        produtos devolvidos. Usaremos o mesmo meio de pagamento que usou para o
        pedido e não incorrerá em nenhuma taxa por esse reembolso.
      </p>
      <h3>Condições para devoluções:</h3>
      <p>
        Para que os bens sejam elegíveis para devolução, certifique-se de que:
        <br />
        Os bens foram adquiridos nos últimos 30 dias. <br />
        Os produtos estão na embalagem original. <br /> <br />
        Os seguintes produtos não podem ser devolvidos:
        <br />
        O fornecimento de produtos feitos de acordo com suas especificações ou
        claramente personalizados. <br />
        O fornecimento de bens que, pela sua natureza, não sejam suscetíveis de
        devolução, por exemplo bens que se deteriorem rapidamente ou cujo prazo
        de validade tenha expirado. <br />O fornecimento de bens que não sejam
        suscetíveis de devolução por razões de proteção da saúde ou higiene e
        que não tenham selo após a entrega. <br />
        O fornecimento de bens que, após a entrega, segundo a sua natureza, se
        misturem indissociavelmente com outros bens. <br />
        Reservamos o direito de recusar a devolução de qualquer mercadoria que
        não atenda às condições de devolução acima, a nosso exclusivo critério.
        <br /> <br />
        Devolução de mercadorias <br />
        É responsável pelo custo e risco de nos devolver as mercadorias. Deve
        enviar a mercadoria para a seguinte morada:
        <br />
        travessa do peso, 349, anta-espinho
        <br />
        Não podemos ser responsabilizados por mercadorias danificadas ou
        perdidas na devolução. Portanto, recomendamos um serviço de correio
        seguro e rastreável. Não podemos emitir um reembolso sem o recebimento
        real das mercadorias ou prova de entrega de devolução recebida.
      </p>
      <h3>Presentes:</h3>
      <p>
        Se os produtos foram marcados como um presente quando comprados e
        enviados diretamente para si, receberá um crédito de oferta no valor da
        sua devolução. Assim que o produto devolvido for recebido, um
        certificado de oferta será enviado.
        <br />
        Se os produtos não foram marcados como um presente ao serem comprados,
        ou se o comprador mandou o pedido para si mesmo para entregá-lo a outro
        mais tarde, enviaremos o reembolso ao comprador.
      </p>
      <br />
      <br />

      <h3>Fale connosco:</h3>
      <p>
        Se tiver alguma dúvida sobre a nossa Política de Devoluções e
        Reembolsos, entre em contacto connosco pelo email{" "}
        <a href="mailto:support@emestrong.com">support@emestrong.com</a>.
      </p>
    </div>
  );
};

export default ReturnRefundPolicy;
