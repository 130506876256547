import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { useStripe } from "@stripe/react-stripe-js";
import { fetchFromAPI } from "../../../helpers";
import {
  selectCartItems,
  selectCartTotal,
} from "../../../store/cart/cart.selector";
import { selectCurrentUser } from "../../../store/user/user.selector";

import {
  ButtonCheckout,
  LockIconWrapper,
  LockIconWrapperOpen,
  WarningText,
  WarningMessage,
  FormButton,
} from "./stripe-checkout.styles";
import { PaymentStart } from "../../../utils/Cookies/facebookPixelEvents";
import { setUserId } from "firebase/analytics";

const StripeCheckout = ({ selectedCountry }) => {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const cartItems = useSelector(selectCartItems);
  const cartTotal = useSelector(selectCartTotal);
  const currentUser = useSelector(selectCurrentUser);
  const [showWarning, setShowWarning] = useState(false);
  const stripe = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setUserId(currentUser.id);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleGuestCheckout = async (e) => {
    e.preventDefault();

    // Calcula o peso total dos itens
    let pesoTotal = 0;
    cartItems.map((item) => {
      if (item.quantity > 1) {
        pesoTotal += item.quantity * item.weight;
      } else {
        pesoTotal += item.weight;
      }
    });

    if (!currentUser) {
      navigate("/auth");
      return;
    }

    if (!selectedCountry) {
      setShowWarning(true);
      return;
    } else {
      setShowWarning(false);
    }

    const line_items = cartItems.map((item) => {
      console.log(item);
      const unitAmount = item.newPrice ? item.newPrice * 100 : item.price * 100;

      return {
        quantity: item.quantity,
        price_data: {
          currency: "eur",
          unit_amount: unitAmount.toFixed(0),
          product_data: {
            name: item.name,
            description: `cor: ${
              item.colors[item.selectedColorId].color
            }; Tamanho: ${item.selectedSize};`,
            images: [item.colors[item.selectedColorId].images[0].imageLink],
            metadata: {
              id: item.id,
            },
          },
        },
      };
    });

    const response = await fetchFromAPI("create-checkout-session", {
      body: {
        line_items,
        customer_email: email,
        selectedCountry,
        pesoTotal,
        userId,
      },
    });

    const { sessionId } = response;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showWarning && <WarningMessage>Please fill all fields.</WarningMessage>}
      <FormButton onSubmit={handleGuestCheckout}>
        <div className="submit-btn">
          {currentUser && cartTotal > 0 ? (
            <ButtonCheckout
              type="submit"
              disabled={!currentUser}
              onClick={PaymentStart}
            >
              Checkout
              <LockIconWrapperOpen>
                <FaLockOpen className="lock-icon" />
              </LockIconWrapperOpen>
            </ButtonCheckout>
          ) : (
            <div>
              <ButtonCheckout
                type="button"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                Checkout
                <LockIconWrapper>
                  <FaLock className="lock-icon" />
                </LockIconWrapper>
              </ButtonCheckout>
              {!currentUser ? (
                <WarningText>Login required!</WarningText>
              ) : (
                <WarningText>Add products!</WarningText>
              )}
            </div>
          )}
        </div>
      </FormButton>
    </>
  );
};

export default StripeCheckout;
