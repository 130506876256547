import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCartCount,
  selectIsCartOpen,
} from "../../store/cart/cart.selector";
import { setIsCartOpen } from "../../store/cart/cart.action";

import { ReactComponent as ShoppingIcon } from "../../assets/shopping-bag.svg";

import { CartIconContainer, ItemCount } from "./cart-icon.styles";

const CartIcon = () => {
  const dispatch = useDispatch();

  const cartCount = useSelector(selectCartCount);
  const isCartOpen = useSelector(selectIsCartOpen);

  const toggleIsCartOpen = () => dispatch(setIsCartOpen(!isCartOpen));

  const cartRef = useRef(null); // Referência para o contêiner do carrinho

  // Adiciona um event listener para fechar o carrinho quando clicar fora dele
  useEffect(() => {
    const closeCartOutsideClick = (event) => {
      if (
        isCartOpen &&
        cartRef.current &&
        !cartRef.current.contains(event.target)
      ) {
        dispatch(setIsCartOpen(false));
      }
    };

    document.addEventListener("click", closeCartOutsideClick);

    return () => {
      document.removeEventListener("click", closeCartOutsideClick);
    };
  }, [dispatch, isCartOpen]);

  return (
    <div ref={cartRef}>
      <CartIconContainer onClick={toggleIsCartOpen}>
        <ShoppingIcon className="shopping-icon" />
        <ItemCount>{cartCount}</ItemCount>
      </CartIconContainer>
    </div>
  );
};

export default CartIcon;
