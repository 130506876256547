import styled from "styled-components";

export const CartItemContainer = styled.div`
  align-items: center;
  margin-right: 7px;
  display: flex;
  height: 80px;
  margin-bottom: 45px;
  img {
    width: 30%;
  }
`;

export const ItemDetails = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;
  span {
    font-size: 16px;
  }
`;

export const RemoveButtonDropdown = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;
