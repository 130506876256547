import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  background-color: #efefef;
`;

export const ButtonStyle1 = styled.div`
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  flex: 50%;
  padding: 16px 10px;
  height: auto;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid rgb(220, 220, 220);
  border-top: 1px solid rgb(220, 220, 220);
`;

export const ButtonStyle2 = styled.div`
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  flex: 50%;
  padding: 16px 10px;
  height: auto;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid rgb(220, 220, 220);
`;

export const SpanText = styled.span`
  text-align: center;
`;
