import styled from "styled-components";

export const SizeGuideClick = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-size: initial;
`;

export const PopupContainer = styled.div`
  margin-right: 10%;

  @media screen and (max-width: 800px) {
    margin-right: unset;
  }
`;

export const ContainerTextos = styled.div`
  justify-content: space-between;
  display: flex;
  svg {
    cursor: pointer;
    font-size: x-large;
    margin-rigth: 10px;
  }
`;

export const SizesContainer = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;

  th,
  td {
    border: 2px solid black;
    border-radius: 5%;
    padding: 5px 30px;
    width: 100%;
    text-align: center;
  }

  tr:nth-of-type(1) {
    background: #dddddd;
  }

  @media screen and (max-width: 1240px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 20px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }
  @media screen and (max-width: 965px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 10px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }
  @media screen and (max-width: 800px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 30px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }
  @media screen and (max-width: 580px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 20px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }

  @media screen and (max-width: 460px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 10px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }

  @media screen and (max-width: 320px) {
    th,
    td {
      border: 2px solid black;
      border-radius: 5%;
      padding: 5px 5px;
      width: 100%;
      text-align: center;
    }

    tr:nth-of-type(1) {
      background: #dddddd;
    }
  }
`;
