import styled from "styled-components";

export const DirectoryContainer = styled.div`
  width: 100%;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageurl }) => `url(${imageurl})`};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Adicionado para cortar a imagem quando for redimensionada */

  @media screen and (max-width: 768px) {
    background-size: contain;
    padding-top: 100%; /* Proporção 1:1 (ajuste conforme necessário) */
  }

  @media screen and (min-width: 769px) {
    height: 570px; /* Altura fixa para dispositivos maiores que 768px */
  }
`;

export const ImageText = styled.div`
  position: absolute;
  bottom: 1px;
  right: 12px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 50%;
  display: grid;

  h2 {
    font-weight: bold;
    margin: 0 0 6px 0;
    font-size: 22px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  span {
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  button {
    padding: 16px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
    font-weight: lighter;
    font-size: 16px;
    color: black;
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Indicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ active }) => (active ? "gray" : "white")};
  border-radius: 50%;
  border: solid 1px black;
  margin: 0 5px;
  cursor: pointer;
`;
