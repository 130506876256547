import React, { useState } from "react";
import { MdClear } from "react-icons/md";

import {
  SizeGuideClick,
  PopupContainer,
  SizesContainer,
  ContainerTextos,
} from "./size-guide.styles";

const SizeGuide = ({ product }) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <SizeGuideClick onClick={openPopup}>Size Guide</SizeGuideClick>
      {showPopup && (
        <PopupContainer>
          <ContainerTextos>
            <span>{product.name} - Tabela de Tamanhos</span>
            <MdClear onClick={closePopup}></MdClear>
          </ContainerTextos>
          <SizesContainer
            dangerouslySetInnerHTML={{ __html: product.sizeGuide }}
          />
        </PopupContainer>
      )}
    </div>
  );
};

export default SizeGuide;
