import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, {
      path: "/",
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };

  return (
    <div style={styles.container}>
      <section style={styles.cookieBox}>
        <p style={styles.cookieText}>
          We use cookies to enhance your user experience. By using our website,
          you agree to our use of cookies.{" "}
          <Link to="/PrivacyPolicies" style={styles.link}>
            Privacy Policy
          </Link>
        </p>
        <button onClick={giveCookieConsent} style={styles.acceptButton}>
          Accept
        </button>
      </section>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "rgb(247, 247, 247)",
    border: "4px solid rgb(17, 17, 17)",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 2px 20px",
    boxSizing: "border-box",
    margin: "16px auto",
    position: "fixed",
    padding: "0px 16px",
    left: "16px",
    right: "16px",
    bottom: "16px",
    maxWidth: "1200px",
    zIndex: 9999,
  },
  cookieBox: {
    boxSizing: "border-box",
    padding: "12px 0px",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1.2rem",
    alignItems: "center",
  },
  cookieText: {
    fontSize: "1rem",
    marginBottom: "10px", // Adicionado espaçamento inferior
  },
  link: {
    color: "#000",
    textDecoration: "underline",
  },
  acceptButton: {
    padding: "0px 22px",
    appearance: "none",
    textAlign: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    height: "42px",
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
    WebkitTapHighlightColor: "transparent",
    display: "inline-block",
    width: "auto",
    fontWeight: "600",
    position: "relative",
    lineHeight: "1",
    fontSize: "16px",
    userSelect: "none",
    cursor: "pointer",
    border: "2px solid rgb(17, 17, 17)",
    color: "rgb(247, 247, 247)",
    background: "rgb(17, 17, 17)",
  },
};

export default CookieConsent;
