import styled from "styled-components";

export const ProductCartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: 95%;
    object-fit: cover;
    margin-bottom: 5px;
  }
  button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }

  @media screen and (max-width: 800px) {
    width: 45vw;
    height: 175px;
    flex-direction: column; /* Altere a direção do flex container para "column" */
    align-items: center; /* Centralize os elementos */
    height: auto;

    button {
      display: block;
      opacity: 0.9;
      min-width: unset;
      padding: 0 10px;
    }

    &:hover {
      .image {
        opacity: unset;
      }
      button {
        opacity: unset;
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 15px;
  gap: 10px;

  @media screen and (max-width: 550px) {
    /*display: grid;*/
    flex-direction: column;
    font-size: 12px;
  }
`;

export const Name = styled.span`
  width: 90%;
  font-weight: bold;

  @media screen and (max-width: 550px) {
    margin-bottom: 5px;
    font-size: 15px;
  }
`;

export const Colors = styled.span`
  font-size: 14px;
  color: gray;
  margin-top: 2px;
  margin-bottom: 2px;

  @media screen and (min-width: 550px) {
    display: none;
  }
`;

export const Price = styled.span`
  color: ${(props) => (props.discounted ? "red" : "inherit")};
  font-size: ${(props) =>
    props.discounted
      ? "14px"
      : "17px"}; // Tamanho da fonte menor quando houver desconto
  text-decoration: ${(props) =>
    props.discounted
      ? "line-through"
      : "none"}; // Linha através do preço antigo quando houver desconto
  margin-left: ${(props) =>
    props.discounted
      ? "5px"
      : "0"}; // Espaçamento à direita quando houver desconto

  @media screen and (max-width: 550px) {
    align-self: start; /* Remova o alinhamento definido anteriormente */
    margin-top: 5px;
    font-size: ${(props) => (props.discounted ? "13px" : "16px")};
  }
`;

export const Labels = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

export const DiscountLabel = styled.span`
  color: white;
  background-color: red;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 3px; /* Adicionado para espaçamento entre as labels */
`;

export const AvailabelLabel = styled.span`
  color: red;
  background-color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 5px; /* Adicionado para espaçamento entre as labels */
`;
