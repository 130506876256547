import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProductSearchContainer,
  ProductCardSearch,
} from "./product-search.styles";
import { FaSearch } from "react-icons/fa";

const ProductSearch = ({ products, onChangeClass }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const inputRef = useRef(null);
  const searchContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = () => {
    onChangeClass("container_Search");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        // O clique ocorreu fora do elemento de pesquisa
        setSearchTerm("");
        setSuggestions([]);
        setSearchVisible(false);
        onChangeClass("container_Search");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchVisible) {
      inputRef.current.focus(); // Defina o foco aqui quando searchVisible é true
    }
  }, [searchVisible]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() === "") {
      setSuggestions([]);
    } else {
      const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );

      setSuggestions(filteredProducts);
    }
  };

  const handleSearchIconClick = () => {
    setSearchVisible(true);
  };

  return (
    <ProductSearchContainer ref={searchContainerRef}>
      {searchVisible ? (
        <input
          type="text"
          placeholder="Pesquisar produtos..."
          value={searchTerm}
          onChange={handleSearchChange}
          ref={inputRef}
        />
      ) : (
        <div onClick={handleSearchIconClick}>
          <FaSearch />
        </div>
      )}

      {suggestions.length > 0 && (
        <div className="suggestions">
          {suggestions.map((product) => (
            <ProductCardSearch
              key={product.id}
              onClick={() => {
                handleClick();
                setSearchTerm("");
                setSuggestions([]);
                setSearchVisible(false);
                navigate(`/shop/all/${product.id}`);
              }}
            >
              <img
                src={product.colors[0].images[0].imageLink}
                alt={`${product.name}`}
              />
              <span>{product.name}</span>
            </ProductCardSearch>
          ))}
        </div>
      )}
    </ProductSearchContainer>
  );
};

export default ProductSearch;
