import "../Terms&Conditions/Terms&Conditions.styles.scss";

const TermsConditions = () => {
  return (
    <div className="container-TermsConditions">
      <h3>VISÃO GERAL</h3>
      <p>
        Este site é operado pela Eme Strong. Em todo o site, os termos "nós",
        "nós" e "nosso" se referem à Eme Strong. Eme Strong oferece este site,
        incluindo todas as informações, ferramentas e serviços disponíveis neste
        site, para você, o usuário, mediante a sua aceitação de todos os termos,
        condições, políticas e avisos estabelecidos aqui.
      </p>
      <p>
        Quaisquer novos recursos ou ferramentas que forem adicionados à loja
        atual também devem estar sujeitos aos Termos de serviço. Você pode
        revisar a versão mais atual dos Termos de serviço quando quiser nesta
        página. Reservamo-nos o direito de atualizar, alterar ou trocar qualquer
        parte desses Termos de serviço ao publicar atualizações e/ou alterações
        no nosso site. É sua responsabilidade verificar periodicamente as
        alterações feitas nesta página. Seu uso contínuo ou acesso ao site após
        a publicação de quaisquer alterações constitui aceitação de tais
        alterações.
      </p>
      <h3>SEÇÃO 1 - TERMOS DA LOJA VIRTUAL</h3>
      <p>
        Ao concordar com estes Termos de serviço, você declara que tem idade
        mínima de maioridade em seu estado ou município de residência, ou que é
        maior de idade em seu estado ou município de residência, e que nos deu
        sua permissão para permitir que qualquer um de seus dependentes menores
        use este site.
      </p>
      <p>
        Você não deve usar nossos produtos para qualquer fim ilegal ou não
        autorizado. Você também não pode, ao usufruir deste Serviço, violar
        quaisquer leis em sua jurisdição (incluindo, entre outras, as leis de
        direitos autorais).
      </p>
      <p>
        Você não deve transmitir nenhum vírus ou qualquer código de natureza
        destrutiva.
      </p>
      <p>
        Violar qualquer um dos Termos resultará na rescisão imediata dos seus
        Serviços.
      </p>
      <h3>SEÇÃO 2 - CONDIÇÕES GERAIS</h3>
      <p>
        Reservamo-nos o direito de recusar o serviço a qualquer pessoa, por
        qualquer motivo, a qualquer momento.
      </p>
      <p>
        Você entende que o seu conteúdo (não incluindo informações de cartão de
        crédito) pode ser transferido sem criptografia e pode: (a) ser
        transmitido por várias redes; e (b) sofrer alterações para se adaptar e
        se adequar às exigências técnicas de conexão de redes ou dispositivos.
        As informações de cartão de crédito sempre são criptografadas durante a
        transferência entre redes.
      </p>
      <p>
        Você concorda em não reproduzir, duplicar, copiar, vender, revender ou
        explorar qualquer parte do Serviço, uso do Serviço, acesso ao Serviço,
        ou qualquer contato no site através do qual o Serviço seja fornecido,
        sem nossa permissão expressa por escrito.
      </p>
      <p>
        Os títulos usados neste contrato são incluídos apenas por conveniência e
        não limitam ou afetam os Termos.
      </p>
      <h3>
        SEÇÃO 3 - PRECISÃO, COMPLETUDE E PERÍODO DE VALIDADE DAS INFORMAÇÕES
      </h3>
      <p>
        Não nos responsabilizamos se as informações disponibilizadas neste site
        não forem precisas, completas ou atuais. O material deste site é
        fornecido apenas para fins informativos e não deve ser usado como a
        única base para tomar decisões sem consultar fontes de informações
        primárias, mais precisas, mais completas ou mais atuais. Qualquer
        utilização do material deste site é por sua conta e risco.
      </p>
      <p>
        Este site pode conter certas informações históricas. As informações
        históricas podem não ser atuais e são fornecidas apenas para sua
        referência. Reservamo-nos o direito de modificar o conteúdo deste site a
        qualquer momento, mas nós não temos obrigação de atualizar nenhuma
        informação em nosso site. Você concorda que é de sua responsabilidade
        acompanhar as alterações no nosso site.
      </p>
      <h3>SEÇÃO 4 - ALTERAÇÕES DO SERVIÇO E DOS PREÇOS</h3>
      <p>
        Os preços de nossos produtos estão sujeitos a alterações sem aviso
        prévio.
      </p>
      <p>
        Reservamo-nos o direito de, a qualquer momento, modificar ou
        descontinuar o Serviço (ou qualquer parte ou conteúdo do mesmo) sem
        aviso prévio em qualquer momento.
      </p>
      <p>
        Não nos responsabilizamos por você ou por qualquer terceiro por qualquer
        modificação, alteração de preço, suspensão ou descontinuação do Serviço.
      </p>
      <h3>SEÇÃO 5 - PRODUTOS OU SERVIÇOS (se aplicável)</h3>
      <p>
        Certos produtos ou serviços podem estar disponíveis exclusivamente
        online através do site. Tais produtos ou serviços podem ter quantidades
        limitadas e são sujeitos a apenas devolução ou troca, de acordo com
        nossa Política de devolução.
      </p>
      <p>
        Fizemos todo o esforço possível para exibir da forma mais precisa as
        cores e imagens dos nossos produtos que aparecem na loja. Não podemos
        garantir que a exibição de qualquer cor no monitor do seu computador
        será precisa.
        <br />
        Reservamo-nos o direito de, mas não somos obrigados a, limitar as vendas
        de nossos produtos ou Serviços para qualquer pessoa, região geográfica
        ou jurisdição. Podemos exercer esse direito conforme cada caso
        individual. Reservamo-nos o direito de limitar as quantidades de
        quaisquer produtos ou serviços que oferecemos. Todas as descrições de
        produtos ou preços de produtos são sujeitos a alteração a qualquer
        momento sem aviso prévio, a nosso critério exclusivo. Reservamo-nos o
        direito de descontinuar qualquer produto a qualquer momento. Qualquer
        oferta de produtos ou serviços neste site é nula onde for proibido por
        lei.
      </p>
      <p>
        Não garantimos que a qualidade de quaisquer produtos, serviços,
        informações ou outros materiais comprados ou obtidos por você vão
        atender às suas expectativas, ou que quaisquer erros no Serviço serão
        corrigidos.
      </p>
      <h3>SEÇÃO 6 - PRECISÃO DE COBRANÇA E INFORMAÇÕES DA CONTA</h3>
      <p>
        Nós nos reservamos o direito de recusar qualquer pedido que você fizer
        conosco. Podemos, a nosso próprio critério, limitar ou cancelar o número
        de produtos comprados por pessoa, por domicílio ou por pedido. Tais
        restrições podem incluir pedidos feitos na mesma conta de cliente, no
        mesmo cartão de crédito, e/ou pedidos que usem a mesma fatura e/ou
        endereço de entrega. Caso façamos uma alteração ou cancelemos um pedido,
        podemos tentar notificá-lo entrando em contato com o endereço de e-mail
        e/ou o número de telefone/endereço de faturamento fornecido no momento
        em que o pedido foi feito. Reservamo-nos o direito de limitar ou proibir
        pedidos que, a nosso critério exclusivo, pareçam ter sido feitos por
        lojistas, revendedores ou distribuidores.
        <br />
        Você concorda em fornecer suas informações de conta e compra completas
        para todas as compras feitas em nossa loja. Você concorda em atualizar
        prontamente sua conta e outras informações, incluindo seu e-mail,
        números de cartão de crédito e datas de validade, para que possamos
        completar suas transações e contatar você quando preciso.
      </p>
      <p>Para mais informações, por favor, veja nossa Política de devolução.</p>
      <h3>SEÇÃO 7 - FERRAMENTAS OPCIONAIS</h3>
      <p>
        Podemos fornecer a você acesso a ferramentas de terceiros sobre as quais
        não monitoramos nem temos controle ou entrada.
      </p>
      <p>
        Você reconhece e concorda que nós fornecemos acesso a tais ferramentas
        no estado em que se encontram e conforme a disponibilidade, sem
        quaisquer garantias, declarações ou condições de qualquer tipo e sem
        qualquer endosso. Não nos responsabilizamos de forma alguma pelo seu uso
        de ferramentas opcionais de terceiros.
      </p>
      <p>
        O uso de ferramentas opcionais oferecidas por meio do site é
        inteiramente por sua conta e risco, e você deve se familiarizar e
        aprovar os termos das ferramentas que são fornecidas por fornecedor(es)
        terceiro(s).
      </p>
      <p>
        Também podemos, futuramente, oferecer novos serviços e/ou recursos por
        meio do site (incluindo o lançamento de novas ferramentas e recursos).
        Tais recursos e/ou serviços novos também devem estar sujeitos a esses
        Termos de serviço.
      </p>
      <h3>SEÇÃO 8 - LINKS DE TERCEIROS</h3>
      <p>
        Determinados conteúdos, produtos e serviços disponíveis através do nosso
        Serviço podem incluir materiais de terceiros.
      </p>
      <p>
        Os links de terceiros nesse site podem direcionar você a sites de
        terceiros que não são Afiliados a nós. Não nos responsabilizamos por
        examinar ou avaliar o conteúdo ou sua precisão. Não garantimos e nem
        temos obrigação ou responsabilidade por quaisquer materiais ou sites de
        terceiros, ou por quaisquer outros materiais, produtos ou serviços de
        terceiros.
      </p>
      <p>
        Não somos responsáveis por quaisquer danos ou prejuízos relacionados com
        a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou
        quaisquer outras transações feitas em conexão com quaisquer sites de
        terceiros. Por favor, leia com cuidado as políticas e práticas de
        terceiros e certifique-se de que você as entende antes de efetuar
        qualquer transação. As queixas, reclamações, preocupações ou questões
        relativas a produtos de terceiros devem ser direcionadas à respectiva
        empresa terceira.
      </p>
      <h3>SEÇÃO 9 - COMENTÁRIOS, FEEDBACK E OUTRAS INTERAÇÕES DO USUÁRIO</h3>
      <p>
        Se, a nosso pedido, você executar determinadas ações específicas (por
        exemplo, inscrições em concursos) ou, sem solicitação de nossa parte,
        enviar ideias criativas, sugestões, propostas, planos ou outros
        materiais, seja online, por e-mail, correio ou de outra forma (em
        conjunto designados "comentários"), você concorda que podemos, a
        qualquer momento, sem restrições, editar, copiar, publicar, distribuir,
        traduzir e usar, em qualquer meio, quaisquer comentários que você
        encaminhar para nós. Não nos responsabilizamos por: (1) manter quaisquer
        comentários em sigilo; (2) indenizar por quaisquer comentários; ou (3)
        responder quaisquer comentários.
        <br />
        Podemos, mas não temos a obrigação, de monitorar, editar ou remover
        conteúdo que nós determinamos a nosso próprio critério ser contra a lei,
        ofensivo, ameaçador, calunioso, difamatório, pornográfico, obsceno ou
        censurável, ou que viole a propriedade intelectual de terceiros ou estes
        Termos de serviço.
        <br />
        Você concorda que seus comentários não violarão qualquer direito de
        terceiros, incluindo direitos autorais, marcas registradas, privacidade,
        personalidade ou outro direito pessoal ou de propriedade. Você concorda
        que os seus comentários não conterão material difamatório, ilegal,
        abusivo ou obsceno. Eles também não conterão nenhum vírus de computador
        ou outro malware que possa afetar a operação do Serviço ou qualquer site
        relacionado. Você não poderá usar endereço de e-mail falso, fingir ser
        alguém diferente de si mesmo ou, de outra forma, enganar a nós ou
        terceiros quanto à origem de quaisquer comentários. Você é o único
        responsável por quaisquer comentários que faz e pela veracidade deles.
        Nós não assumimos qualquer responsabilidade ou obrigação por quaisquer
        comentários publicados por você ou por terceiros.
      </p>
      <h3>SEÇÃO 10 - INFORMAÇÕES PESSOAIS</h3>
      <p>
        Sua apresentação de informações pessoais por meio da loja é regida pela
        nossa Política de privacidade. Consulte a nossa Política de privacidade.
      </p>
      <h3>SEÇÃO 11 - ERROS, IMPRECISÕES E OMISSÕES</h3>
      <p>
        Por vezes, pode haver informações em nosso site ou no Serviço que
        contenham erros tipográficos, imprecisões ou omissões que possam estar
        relacionadas a descrições de produtos, preços, promoções, ofertas,
        cobranças de frete de produtos, tempos de trânsito e disponibilidade.
        Reservamo-nos o direito de corrigir quaisquer erros, imprecisões ou
        omissões, e de alterar ou atualizar informações ou cancelar pedidos caso
        qualquer informação no Serviço ou em qualquer site relacionado seja
        imprecisa, a qualquer momento e sem aviso prévio (até mesmo depois de
        você ter enviado o seu pedido).
      </p>
      <p>
        Não assumimos nenhuma obrigação de atualizar, alterar ou explicar
        informações no Serviço ou em qualquer site relacionado, incluindo, entre
        outras, as informações sobre preços, exceto conforme exigido por lei.
        Nenhuma atualização específica ou data de atualização no Serviço ou em
        qualquer site relacionado deve ser utilizada para indicar que todas as
        informações do Serviço ou em qualquer site relacionado tenham sido
        modificadas ou atualizadas.
      </p>
      <h3>SEÇÃO 12 - USOS PROIBIDOS</h3>
      <p>
        Além de outras proibições estabelecidas nos Termos de serviço, você está
        proibido de usar o site ou seu conteúdo para: (a) qualquer finalidade
        ilícita; (b) solicitar a outros que realizem ou participem de atos
        ilegais; (c) violar quaisquer regulamentos, regras, leis ou leis locais,
        internacionais, federais, municipais ou estaduais; (d) infringir ou
        violar nossos direitos de propriedade intelectual ou os direitos de
        propriedade intelectual de terceiros; (e) assediar, abusar, insultar,
        prejudicar, difamar, caluniar, desacreditar, intimidar ou discriminar
        com base no gênero, orientação sexual, religião, etnia, raça, idade,
        nacionalidade ou deficiência; (f) apresentar informações falsas ou
        enganosas;
        <br />
        (g) enviar ou transmitir vírus ou qualquer outro tipo de código
        malicioso, que será ou poderá ser usado de qualquer forma que afete a
        funcionalidade ou operação do Serviço ou de qualquer site relacionado,
        outros sites ou a internet; (h) coletar ou rastrear as informações
        pessoais de terceiros; (i) prática de spam, phishing, pharm, pretext,
        spider, crawl ou scrape; (j) qualquer propósito obsceno ou imoral; ou
        para (k) interferir ou contornar os recursos de segurança do Serviço ou
        de qualquer site relacionado, outros sites ou a internet. Reservamo-nos
        o direito de rescindir o seu uso do Serviço ou de qualquer site
        relacionado por violar qualquer um dos usos proibidos.
      </p>
      <h3>SEÇÃO 13 - ISENÇÃO DE GARANTIAS; LIMITAÇÃO DE RESPONSABILIDADE</h3>
      <p>
        Nós não prometemos, representamos ou garantimos que seu uso de nosso
        serviço será ininterrupto, oportuno, seguro ou livre de erros.
        <br />
        Não garantimos que os resultados que possam ser obtidos pelo uso do
        serviço serão precisos ou confiáveis.
      </p>
      <p>
        Você concorda que, de tempos em tempos, podemos remover o serviço por
        períodos indefinidos de tempo ou cancelá-lo a qualquer momento, sem
        enviar aviso prévio para você.
        <br />
        Você concorda que o seu uso ou incapacidade de usar o serviço é por sua
        conta e risco. O serviço e todos os produtos e serviços entregues
        através do serviço são (exceto conforme declarado por nós) fornecidos
        sem garantia e conforme a disponibilidade para seu uso, sem qualquer
        declaração, garantias ou condições de qualquer tipo, expressas ou
        implícitas, incluindo todas as garantias implícitas ou condições de
        comercialização, quantidade, adequação a uma finalidade específica,
        durabilidade, título e não violação.
        <br />
        Em nenhum caso, a Eme Strong , nossos diretores, executivos,
        funcionários, Afiliados, agentes, contratados, estagiários,
        fornecedores, provedores de serviços ou licenciantes serão responsáveis
        por qualquer dano, perda, reivindicação ou qualquer dano direto,
        indireto, incidental, punitivo, especial ou consequencial de qualquer
        tipo, incluindo, entre outros, lucros perdidos, perda de receita, perda
        de economias, perda de dados, custos de substituição ou quaisquer danos
        semelhantes, seja com base em contrato, ato ilícito (incluindo
        negligência), responsabilidade estrita ou não, decorrentes do uso de
        qualquer um dos serviços ou quaisquer produtos adquiridos usando o
        serviço, ou para qualquer outra reivindicação relacionada de qualquer
        forma ao seu uso do serviço ou qualquer produto, incluindo, entre
        outros, quaisquer erros ou omissões em qualquer conteúdo, ou qualquer
        perda ou dano de qualquer tipo incorrido como resultado do uso do
        serviço ou de qualquer conteúdo (ou produto) publicado, transmitido ou
        de outra forma disponibilizado através do serviço, mesmo se avisado de
        sua possibilidade.
        <br />
        Como alguns estados ou jurisdições não permitem a exclusão ou a
        limitação de responsabilidade por danos indiretos ou incidentais, em
        tais estados ou jurisdições, a nossa responsabilidade será limitada à
        extensão máxima permitida por lei.
      </p>
      <h3>SEÇÃO 14 - INDENIZAÇÃO</h3>
      <p>
        Você concorda em indenizar, defender e isentar Eme Strong e nossa
        matriz, subsidiárias, afiliadas, parceiros, executivos, diretores,
        agentes, contratados, licenciantes, prestadores de serviços,
        subcontratados, fornecedores, estagiários e funcionários, de qualquer
        reivindicação ou demanda, incluindo honorários advocatícios razoáveis,
        feitos por terceiros, devido a ou decorrente de sua violação destes
        Termos de serviço ou dos documentos que incorporam por referência, ou
        sua violação de qualquer lei ou dos direitos de terceiros.
      </p>
      <h3>SEÇÃO 15 - INDEPENDÊNCIA</h3>
      <p>
        No caso de qualquer disposição destes Termos de serviço ser considerada
        ilegal, nula ou inexequível, tal disposição será, no entanto, executável
        na extensão máxima permitida pela lei aplicável, e a parte inexequível
        será considerada como cortada destes Termos de serviço, e tal
        determinação não afetará a validade e aplicabilidade de quaisquer outras
        disposições remanescentes.
      </p>
      <h3>SEÇÃO 16 - RESCISÃO</h3>
      <p>
        As obrigações e as responsabilidades das partes incorridas antes da data
        de rescisão sobreviverão à rescisão deste contrato para todos os fins.
      </p>
      <p>
        Estes Termos de serviço permanecem em vigor, a menos que sejam
        rescindidos por você ou por nós. Você pode rescindir estes Termos de
        serviço a qualquer momento, notificando-nos que já não deseja utilizar
        os nossos serviços, ou quando você deixar de usar o nosso site.
        <br />
        Se em nosso critério exclusivo você não cumprir com qualquer termo ou
        disposição destes Termos de serviço, nós também podemos rescindir este
        contrato a qualquer momento sem aviso prévio e você ficará responsável
        por todas as quantias devidas até a data da rescisão; também podemos lhe
        negar acesso a nossos Serviços (ou qualquer parte deles).
      </p>
      <h3>SEÇÃO 17 - ACORDO INTEGRAL</h3>
      <p>
        A falha de nossa parte em exercer ou executar qualquer direito ou
        disposição destes Termos de Serviço não constituirá uma renúncia de tal
        direito ou disposição.
      </p>
      <p>
        Estes Termos de serviço e quaisquer políticas ou normas operacionais
        publicadas por nós neste site ou no que diz respeito ao serviço
        constituem a totalidade do acordo entre nós. Estes termos regem o seu
        uso do Serviço, substituindo quaisquer acordos anteriores ou
        contemporâneos, comunicações e propostas, sejam verbais ou escritos,
        entre você e nós (incluindo, entre outros, quaisquer versões anteriores
        dos Termos de serviço).
        <br />
        Quaisquer ambiguidades na interpretação destes Termos de serviço não
        devem ser interpretadas contra a parte que os redigiu.
      </p>
      <h3>SEÇÃO 18 - LEGISLAÇÃO APLICÁVEL</h3>
      <p>
        Estes Termos de serviço e quaisquer contratos separados nos quais
        fornecemos a você os Serviços serão regidos e interpretados de acordo
        com as leis de Rua monte do Lirio,171, Espinho , PT-01, 4500-078,
        Portugal.
      </p>
      <h3>SEÇÃO 19 - MUDANÇAS NOS TERMOS DE SERVIÇO</h3>
      <p>
        Você pode revisar a versão mais atual dos Termos de serviço a qualquer
        momento nesta página.
      </p>
      <p>
        Reservamo-nos o direito, a nosso critério, de atualizar, modificar ou
        substituir qualquer parte destes Termos de serviço ao publicar
        atualizações e alterações no nosso site. É sua responsabilidade
        verificar periodicamente se há alterações em nosso site. Seu uso
        contínuo ou acesso ao nosso site ou ao Serviço após a publicação de
        quaisquer alterações a estes Termos de serviço constitui aceitação
        dessas alterações.
      </p>
      <h3>SEÇÃO 20 - INFORMAÇÕES DE CONTATO</h3>
      <p>
        Perguntas sobre os Termos de serviço devem ser enviadas para nós em{" "}
        <a href="mailto:support@emestrong.com">support@emestrong.com</a>.
      </p>
    </div>
  );
};

export default TermsConditions;
