import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import OrderItem from "../../components/orders/order-item/orderItem.component";
import Spinner from "../../components/spinner/spinner.component";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import "./admin.styles.scss";

const Admin = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const categoriesMap = useSelector(selectCategoriesMap);

  useEffect(() => {
    const fetchAllOrders = async () => {
      try {
        const OrdersCollection = collection(db, "orders");
        const OrdersSnapshot = await getDocs(OrdersCollection);
        const orders = OrdersSnapshot.docs.map((doc) => doc.data());

        orders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        setAllOrders(orders);
      } catch (error) {
        console.error("Error fetching all orders", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllOrders();
  }, []);

  const getProductName = (productId) => {
    const product = categoriesMap["all"].find((item) => item.id === productId);
    return product ? product.name : "Product not found";
  };

  const getProductImage = (productId, productColor) => {
    const product = categoriesMap["all"].find((item) => item.id === productId);
    if (!product) return null;

    const colorInfo = product.colors.find(
      (color) => color.color === productColor
    );
    return colorInfo ? colorInfo.images[0].imageLink : null;
  };

  return (
    <div className="container-admin">
      <h2>All Orders</h2>

      {isLoading ? (
        <Spinner />
      ) : allOrders.length ? (
        <div className="orders-list">
          {allOrders.map((order, index) => (
            <OrderItem
              key={index}
              order={order}
              index={index}
              getProductImage={getProductImage}
              getProductName={getProductName}
            />
          ))}
        </div>
      ) : (
        <div className="no-orders">
          <span>No orders found.</span>
        </div>
      )}
    </div>
  );
};

export default Admin;
