import React, { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";

import {
  ProductCartContainer,
  Footer,
  Name,
  Colors,
  Price,
  DiscountLabel,
  AvailabelLabel,
  Labels,
} from "./product-card.styles";

const ProductCard = ({ product }) => {
  const { name, price, colors, id } = product;
  const [hoveredColorIndex, setHoveredColorIndex] = useState(0);
  const currentColor = colors[hoveredColorIndex];

  const discountedPrice = useMemo(() => {
    return price - price * (currentColor.discount / 100);
  }, [price, currentColor.discount]);

  const isDiscounted = useMemo(() => {
    return currentColor.discount > 0;
  }, [currentColor.discount]);

  const checkAvailability = useCallback(() => {
    return !Object.values(currentColor.tamanhos).some(
      (quantity) => quantity > 0
    );
  }, [currentColor.tamanhos]);

  const formatCurrency = useCallback((amount) => {
    return Number(amount).toFixed(2);
  }, []);

  const handleMouseEnter = useCallback(() => {
    // Avance para a próxima cor quando o mouse passar por cima
    setHoveredColorIndex((prevIndex) =>
      prevIndex === colors.length - 1 ? 0 : prevIndex + 1
    );
  }, [colors.length]);

  return (
    <Link to={`/shop/all/${id}`}>
      <ProductCartContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setHoveredColorIndex(0)}
      >
        <Labels>
          {isDiscounted && (
            <DiscountLabel>{currentColor.discount}%</DiscountLabel>
          )}
          {checkAvailability() && <AvailabelLabel>SOLD OUT</AvailabelLabel>}
        </Labels>
        <img src={currentColor.images[0].imageLink} alt={`${name}`} />
        <Footer>
          <Name>{name}</Name>
          <Colors>
            {colors.length} {colors.length === 1 ? "color" : "colors"}
          </Colors>
          {isDiscounted ? (
            <div>
              <Price>{formatCurrency(discountedPrice)}€</Price>
              <Price discounted={isDiscounted}>{formatCurrency(price)}€</Price>
            </div>
          ) : (
            <Price>{formatCurrency(price)}€</Price>
          )}
        </Footer>
      </ProductCartContainer>
    </Link>
  );
};

export default ProductCard;
