import React from "react";
import "./productItem.styles.scss";

const ProductItem = ({ product, getProductImage, getProductName }) => {
  const productColor = product.description.split(";")[0].split(":")[1].trim();

  return (
    <div className="product-item">
      <img
        src={getProductImage(product.id, productColor)}
        alt={getProductName(product.id)}
        className="product-image"
      />
      <div className="product-details">
        <p>
          <strong>Product:</strong> {getProductName(product.id)}
        </p>
        <p>
          <strong>Description:</strong> {product.description}
        </p>
        <p>
          <strong>Quantity:</strong> {product.quantity}
        </p>
      </div>
    </div>
  );
};

export default ProductItem;
