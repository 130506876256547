import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";

import {
  DirectoryContainer,
  BackgroundImage,
  ImageText,
  IndicatorContainer,
  Indicator,
} from "./directory.styles";

const Directory = () => {
  const [homeBanners, setHomeBanners] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomeBanners = async () => {
      try {
        // Buscar os banners na coleção "homeBanner"
        const homeBannerCollection = collection(db, "homeBanner");
        const homeBannerSnapshot = await getDocs(homeBannerCollection);

        const bannersData = homeBannerSnapshot.docs.map((doc) => doc.data());
        const allBanners = bannersData.flatMap((data) => data.banners);

        setHomeBanners(allBanners);
      } catch (error) {
        console.error("Erro ao buscar os banners:", error);
      }
    };

    fetchHomeBanners();
  }, []);

  const goToNextBanner = () => {
    setCurrentBannerIndex((prevIndex) =>
      prevIndex === homeBanners.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(goToNextBanner, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentBannerIndex]);

  // Função para selecionar um banner específico
  const selectBanner = (index) => {
    setCurrentBannerIndex(index);
  };

  // Função para verificar se o dispositivo é um celular
  const isMobile = () => {
    return window.innerWidth <= 768; // Você pode ajustar esse limite conforme necessário
  };

  return (
    <DirectoryContainer>
      {homeBanners.map((banner, index) => (
        <div
          key={banner.id}
          style={{
            width: "100%",
            display: index === currentBannerIndex ? "block" : "none",
          }}
        >
          <BackgroundImage
            imageurl={isMobile() ? banner.imageUrlMobile : banner.imageUrl}
          >
            <ImageText>
              <h2>{banner.title}</h2>
              <span>{banner.description}</span>
              <button
                onClick={() => {
                  navigate(banner.route);
                }}
              >
                Find your fit
              </button>
            </ImageText>
          </BackgroundImage>
        </div>
      ))}
      <IndicatorContainer>
        {homeBanners.map((_, index) => (
          <Indicator
            key={index}
            onClick={() => selectBanner(index)}
            active={index === currentBannerIndex}
          />
        ))}
      </IndicatorContainer>
    </DirectoryContainer>
  );
};

export default Directory;
