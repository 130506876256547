import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { collection, getDocs } from "firebase/firestore";

import { selectCategoriesMap } from "../../store/categories/category.selector";
import Directory from "../../components/directory/directory.component";
import HighlightedProducts from "../../components/highlighted-product/highlighted-product.component";
import HomeButtons from "../../components/home-buttons/home-buttons.component";
import Spinner from "../../components/spinner/spinner.component";

import { db } from "../../utils/firebase/firebase.utils";
import { fetchCategoriesStart } from "../../store/categories/category.action";

import "./home.styles.scss";

const Home = () => {
  const dispatch = useDispatch();
  const [highlightedProductIds, setHighlightedProductIds] = useState([]);
  const [lastDropProducts, setLastDropProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberLastDrop, setNumberLastDrop] = useState(null);
  const categoriesMap = useSelector(selectCategoriesMap);
  const productsToShow = categoriesMap["all"] || [];

  useEffect(() => {
    dispatch(fetchCategoriesStart());
  }, [dispatch]);

  useEffect(() => {
    const fetchHighlightedProductIds = async () => {
      try {
        const highlightedProductsCollection = collection(
          db,
          "highlightedProducts"
        );
        const highlightedProductsSnapshot = await getDocs(
          highlightedProductsCollection
        );
        const productIds = highlightedProductsSnapshot.docs.map(
          (doc) => doc.data().productId
        );

        setHighlightedProductIds(productIds);
      } catch (error) {
        console.error("Erro ao buscar IDs de produtos destacados:", error);
      }
    };

    const fetchLastDropProducts = async () => {
      try {
        const dropsCollection = collection(db, "categories");
        const dropsSnapshot = await getDocs(dropsCollection);
        const drops = dropsSnapshot.docs.map((doc) => doc.data().title);

        const lastDropTitle = drops.sort((a, b) => {
          const getDropNumber = (dropTitle) =>
            parseInt(dropTitle.split("º")[0]);
          return getDropNumber(b) - getDropNumber(a);
        })[0];

        const numberLastDrop = parseInt(lastDropTitle.split("º")[0]);
        setNumberLastDrop(numberLastDrop);

        const lastDropProducts = categoriesMap[lastDropTitle] || [];

        setLastDropProducts(lastDropProducts);
      } catch (error) {
        console.error("Erro ao buscar produtos do último drop:", error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchHighlightedProductIds();
      await fetchLastDropProducts();
      setLoading(false);
    };

    if (Object.keys(categoriesMap).length > 0) {
      fetchData();
    }
  }, [categoriesMap]);

  if (loading) {
    return <Spinner />;
  }

  const highlightedProducts = productsToShow.filter((product) =>
    highlightedProductIds.includes(product.id)
  );

  return (
    <div>
      <div className="home-container">
        <div className="home-homeButtons">
          <HomeButtons />
        </div>
        <div>
          <Directory />
          <Outlet />
          {highlightedProducts.length > 0 && (
            <HighlightedProducts
              title="Highlighted products"
              redirectLink="/shop/all"
              products={highlightedProducts}
            />
          )}
          {lastDropProducts.length > 0 && (
            <HighlightedProducts
              title="New In"
              redirectLink={`/shop/${numberLastDrop}º%20drop`}
              products={lastDropProducts}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
