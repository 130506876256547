import styled from "styled-components";

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  @media only screen and (max-width: 650px) {
    font-size: 15px;
  }
`;

export const ImageContainer = styled.div`
  width: 23%;
  padding-right: 15px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const BaseSpan = styled.span`
  width: 23%;
  display: inline-grid;

  span {
    &:nth-child(2) {
      color: gray; /* Altera a cor do texto para cinza */
      font-size: 0.8em; /* Altera o tamanho da fonte para 80% do tamanho padrão */
    }
  }
`;

export const Quantity = styled(BaseSpan)`
  display: flex;
`;

export const Arrow = styled.div`
  cursor: pointer;
`;

export const Value = styled.span`
  margin: 0 10px;
`;

export const RemoveButton = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;

export const StyledArrow = styled(Arrow)`
  color: ${(props) =>
    props.disabled
      ? "gray"
      : "black"}; // Altera a cor para cinza se estiver desabilitado
  pointer-events: ${(props) =>
    props.disabled
      ? "none"
      : "auto"}; // Impede eventos de clique se estiver desabilitado
`;
