import React, { useState, useEffect } from "react";
import "./navigation-banner.styles.scss";

const NavigationBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`container-navigation-banner ${
        isVisible ? "fadeIn" : "fadeOut"
      }`}
    >
      <p className="text-navigation-banner">Special free gift over 40€</p>
    </div>
  );
};

export default NavigationBanner;
