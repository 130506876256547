import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { selectCartItems } from "../../store/cart/cart.selector";
import {
  addItemToCart,
  clearItemFromCart,
  removeItemFromCart,
} from "../../store/cart/cart.action";
import Spinner from "../spinner/spinner.component";
import {
  CheckoutItemContainer,
  ImageContainer,
  BaseSpan,
  Quantity,
  StyledArrow,
  Value,
  RemoveButton,
} from "./checkout-item.styles";

const CheckoutItem = ({ cartItem }) => {
  const {
    name,
    colors,
    price,
    quantity,
    selectedSize,
    selectedColorId,
    newPrice,
  } = cartItem;
  const [isComponentReady, setIsComponentReady] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false); // Estado para desabilitar o botão de aumento
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  const clearItemHandler = () => {
    dispatch(clearItemFromCart(cartItems, cartItem));
  };

  const addItemHandler = () => {
    const maxQuantity = cartItem.colors[selectedColorId].tamanhos[selectedSize];
    const quantityInCart = cartItems.reduce((total, item) => {
      if (
        item.id === cartItem.id &&
        item.selectedSize === selectedSize &&
        item.selectedColorId === selectedColorId
      ) {
        return total + item.quantity;
      }
      return total;
    }, 0);

    if (quantityInCart < maxQuantity) {
      dispatch(addItemToCart(cartItems, cartItem));
    } else {
      setIsAddDisabled(true);
    }
  };

  const removeItemHandler = () => {
    dispatch(removeItemFromCart(cartItems, cartItem));
    setIsAddDisabled(false);
  };

  useEffect(() => {
    setIsComponentReady(true);
  }, []);

  return (
    <div>
      {isComponentReady ? (
        <CheckoutItemContainer>
          <ImageContainer>
            <img
              src={colors[selectedColorId].images[0].imageLink}
              alt={`${name}`}
            />
          </ImageContainer>
          <BaseSpan>
            <span>{name}</span>
            <span>Tamanho: {selectedSize}</span>
          </BaseSpan>
          <Quantity>
            <StyledArrow onClick={removeItemHandler}>&#10094;</StyledArrow>
            <Value>{quantity}</Value>
            <StyledArrow onClick={addItemHandler} disabled={isAddDisabled}>
              &#10095;
            </StyledArrow>
          </Quantity>
          {newPrice ? (
            <BaseSpan> {formatCurrency(newPrice)}€</BaseSpan>
          ) : (
            <BaseSpan> {formatCurrency(price)}€</BaseSpan>
          )}
          <RemoveButton onClick={clearItemHandler}>&#10005;</RemoveButton>
        </CheckoutItemContainer>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CheckoutItem;
