import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../components/button/button.component";
import Notification from "../../components/Notification/Notification";
import { addItemToCart } from "../../store/cart/cart.action";
import { selectCartItems } from "../../store/cart/cart.selector";

import "./bonus-product-card.styles.scss";

const BonusProductCard = ({ product }) => {
  const { name, price, colors, id } = product;
  const [idColor, setIdColor] = useState(0);
  const currentColor = colors[idColor];
  const discountedPrice = price - price * (currentColor.discount / 100);
  const isDiscounted = currentColor.discount > 0;
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  //const cartItems = useSelector(selectCartItems);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false); // Estado para controlar a exibição do alerta
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const sizesOrder = ["XS", "S", "M", "L", "XL", "XXL"];

  const checkAvailability = () => {
    const sizes = Object.keys(currentColor.tamanhos);
    for (const size of sizes) {
      if (currentColor.tamanhos[size] > 0) {
        return false;
      }
    }
    return true;
  };

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  const handleColorButtonClick = (colorCode, newIdColor) => {
    setCurrentImageIndex(0);
    setIdColor(newIdColor);
    setSelectedColor(colorCode);
    setSelectedSize(null);
  };

  const handleSizeButtonClick = (size) => {
    setSelectedSize(size);
    setShowAlert(false);
  };

  const showTemporaryNotification = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
  };

  const verificaQuantidadeDeBrindes = () => {
    // Verificar se já existe um produto com preço 0.00€ no carrinho
    const hasFreeProductInCart = cartItems.some(
      (cartItem) => cartItem.newPrice === "0.00"
    );

    // Se já houver um produto com preço 0.00€ no carrinho, não permitir adicionar outro
    if (hasFreeProductInCart) {
      setShowAlert(true);
      showTemporaryNotification(
        "You can only add one bonus product to the cart."
      );
      return false;
    }

    return true;
  };

  const addProductToCart = () => {
    if (!verificaQuantidadeDeBrindes()) {
      return; // Não adiciona o produto se a verificação falhar
    }
    const quantidadeDisponivel = product.colors[idColor].tamanhos[selectedSize];

    if (quantidadeDisponivel > 0) {
      // Verificar se a quantidade já está no carrinho
      const quantidadeNoCarrinho = cartItems.reduce((total, cartItem) => {
        if (
          cartItem.selectedSize === selectedSize &&
          cartItem.selectedColorId === idColor
        ) {
          return total + cartItem.quantity;
        }
        return total;
      }, 0);

      if (quantidadeNoCarrinho < quantidadeDisponivel) {
        let newPrice = 0; // Preço padrão
        if (isDiscounted) {
          // Se houver um desconto, use o preço com desconto
          newPrice = 0;
        }

        // Crie uma nova cópia do produto com o tamanho selecionado e preço apropriado
        const productWithSize = {
          ...product,
          selectedSize: selectedSize,
          selectedColorId: idColor,
          selectedColorCode: selectedColor,
          newPrice: newPrice.toFixed(2),
        };

        dispatch(addItemToCart(cartItems, productWithSize));
        setShowAlert(false);
        showTemporaryNotification("Added to cart");
      } else {
        showTemporaryNotification("Maximum quantity reached for selected size");
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <div className="CartContainer">
      {showNotification && <Notification message={notificationMessage} />}
      <Link to={`/shop/all/${id}`}>
        <div className="BonusProductCartContainer">
          <img src={currentColor.images[0].imageLink} alt={`${name}`} />
          <div className="Footer">
            <span className="Name">{name}</span>
            <span className="Price" discounted={false}>
              FREE
            </span>
          </div>
        </div>
      </Link>
      <div className="card-more-information">
        <div className="card-colors-container">
          <strong>Colors:</strong>{" "}
          <div className="color-container">
            {colors.map((colorOption, index) => (
              <button
                key={colorOption.color}
                className={`color-button ${
                  index === idColor ? "selecionado" : ""
                }`}
                style={{ backgroundColor: `${colorOption.colorCode}` }}
                onClick={() =>
                  handleColorButtonClick(colorOption.colorCode, index)
                }
              ></button>
            ))}
          </div>
        </div>
        <div className="card-sizes-container">
          <strong>Sizes:</strong>{" "}
          <div className="tamanho-container">
            {colors[idColor].tamanhos ? (
              sizesOrder.map((size) => {
                const quantidade = colors[idColor].tamanhos[size];

                if (quantidade === -1) {
                  // Não renderizar o botão quando for igual a -1
                  return null;
                }

                return (
                  <button
                    key={size}
                    className={`button ${
                      selectedSize === size ? "selecionado" : ""
                    } ${quantidade === 0 ? "esgotado" : ""}`}
                    onClick={() => handleSizeButtonClick(size)}
                    disabled={quantidade === 0}
                  >
                    {size}
                    {quantidade === 0 && (
                      <svg>
                        <line x1="10%" y1="90%" x2="90%" y2="10%"></line>
                      </svg>
                    )}
                  </button>
                );
              })
            ) : (
              <div>Tamanhos não disponíveis</div>
            )}
          </div>
        </div>
      </div>
      <div className="button_container">
        <Button
          buttonType={BUTTON_TYPE_CLASSES.inverted}
          onClick={addProductToCart}
        >
          Add to cart
        </Button>
      </div>
    </div>
  );
};

export default BonusProductCard;
