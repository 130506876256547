import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductItem from "../product-item/productItem.component";
import {
  updateOrderStatus,
  updateOrderAdditionalInfo,
} from "../../../utils/firebase/order.utils";
import { selectCurrentUser } from "../../../store/user/user.selector";
import "./orderItem.styles.scss";

const OrderItem = ({ order, index, getProductImage, getProductName }) => {
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(order.delivery_status);
  const [additionalInfo, setAdditionalInfo] = useState(
    order.additional_information || ""
  );
  const [isEditing, setIsEditing] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();

  const isAdminPage = location.pathname.startsWith("/admin");
  const isAdmin = currentUser?.isAdmin || false;

  const handleToggleExpand = () => {
    setExpandedOrder(expandedOrder === order.id ? null : order.id);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await updateOrderStatus(order.userId, order.paymentIntentId, newStatus);
      setDeliveryStatus(newStatus);
    } catch (error) {
      console.error("Error updating delivery status", error);
    }
  };

  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
  };

  const handleSaveAdditionalInfo = async () => {
    try {
      await updateOrderAdditionalInfo(
        order.userId,
        order.paymentIntentId,
        additionalInfo
      );
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating additional information", error);
    }
  };

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  return (
    <div className="order-item">
      <h3>
        Order {order.shipping.name} #{index + 1}
      </h3>
      <p>
        <strong>Order Date:</strong>{" "}
        {new Date(order.createdAt.seconds * 1000).toLocaleDateString()}
      </p>
      <p>
        <strong>Payment Status:</strong> {order.payment_status}
      </p>
      <p>
        <strong>Delivery Status:</strong>{" "}
        {isAdminPage && isAdmin ? (
          <select
            value={deliveryStatus}
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="cancelled">Cancelled</option>
          </select>
        ) : (
          deliveryStatus
        )}
      </p>
      <div className="products-list">
        <h4>Products:</h4>
        {order.products.map((product, i) => (
          <ProductItem
            key={i}
            product={product}
            getProductImage={getProductImage}
            getProductName={getProductName}
          />
        ))}
      </div>
      <div className="order-additional-info">
        <h4>Additional Information:</h4>
        {isEditing ? (
          <>
            <textarea
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
            />
            <button onClick={handleSaveAdditionalInfo}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{additionalInfo || "No additional information available."}</p>
            {isAdminPage && isAdmin && (
              <button onClick={() => setIsEditing(true)}>Edit</button>
            )}
          </>
        )}
      </div>
      <button className="toggle-address-btn" onClick={handleToggleExpand}>
        {expandedOrder === order.id ? "Hide Address" : "Show Address"}
      </button>
      {expandedOrder === order.id && (
        <div className="order-address">
          <h4>Shipping Address:</h4>
          {order.shipping.phone && (
            <p>
              <strong>Phone Number: </strong>
              {order.shipping.phone}
            </p>
          )}
          {order.shipping.name && (
            <p>
              <strong>Name: </strong>
              {order.shipping.name}
            </p>
          )}
          {order.shipping.email && (
            <p>
              <strong>Email: </strong>
              {order.shipping.email}
            </p>
          )}
          {order.shipping.address.city && (
            <p>
              <strong>City: </strong>
              {order.shipping.address.city}
            </p>
          )}
          {order.shipping.address.line1 && (
            <p>
              <strong>Address: </strong>
              {order.shipping.address.line1}
            </p>
          )}
          {order.shipping.address.line2 && (
            <p>
              <strong>Address 2: </strong>
              {order.shipping.address.line2}
            </p>
          )}
          {order.shipping.address.state && (
            <p>
              <strong>State: </strong>
              {order.shipping.address.state}
            </p>
          )}
          {order.shipping.address.postal_code && (
            <p>
              <strong>Postal Code: </strong>
              {order.shipping.address.postal_code}
            </p>
          )}
        </div>
      )}
      <p>
        <strong>Total: </strong>
        {formatCurrency(order.total)}€
      </p>
    </div>
  );
};

export default OrderItem;
