import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase.utils";

export const updateOrderStatus = async (userId, paymentIntentId, newStatus) => {
  try {
    const ordersCollection = collection(db, "orders");
    const q = query(
      ordersCollection,
      where("userId", "==", userId),
      where("paymentIntentId", "==", paymentIntentId)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error("No matching order found");
    }

    querySnapshot.forEach(async (docSnap) => {
      const orderDocRef = doc(db, "orders", docSnap.id);
      await updateDoc(orderDocRef, { delivery_status: newStatus });
    });
  } catch (error) {
    console.error("Error updating order status:", error);
  }
};

export const updateOrderAdditionalInfo = async (
  userId,
  paymentIntentId,
  additionalInfo
) => {
  try {
    const ordersCollection = collection(db, "orders");
    const q = query(
      ordersCollection,
      where("userId", "==", userId),
      where("paymentIntentId", "==", paymentIntentId)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error("No matching order found");
    }

    querySnapshot.forEach(async (docSnap) => {
      const orderDocRef = doc(db, "orders", docSnap.id);
      await updateDoc(orderDocRef, { additional_information: additionalInfo });
    });
  } catch (error) {
    console.error("Error updating additional information:", error);
  }
};
