import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../components/button/button.component";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import { selectCartItems } from "../../store/cart/cart.selector";
import { addItemToCart } from "../../store/cart/cart.action";
import Notification from "../../components/Notification/Notification";
import SizeGuide from "../../components/Size-Guide/size-guide.component";
import "./product-page.styles.scss";

const ProductPage = () => {
  const { id } = useParams();
  const categoriesMap = useSelector(selectCategoriesMap);
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [idColor, setIdColor] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const sizesOrder = ["XS", "S", "M", "L", "XL", "XXL"];

  const pro = useMemo(() => {
    return categoriesMap["all"]?.find((product) => product.id === parseInt(id));
  }, [categoriesMap, id]);

  const { price, colors } = pro || {};
  const { discount, tamanhos, images, color, colorCode } = colors
    ? colors[idColor]
    : {};

  const discountedPrice = useMemo(() => {
    return price ? price - (price * discount) / 100 : 0;
  }, [price, discount]);

  const isDiscounted = useMemo(() => {
    return discount > 0;
  }, [discount]);

  const preloadImage = useCallback((src) => {
    const img = new Image();
    img.src = src;
  }, []);

  useEffect(() => {
    if (pro) {
      setSelectedColor(color);
      // Preload next and previous images
      if (currentImageIndex > 0) {
        preloadImage(images[currentImageIndex - 1].imageLink);
      }
      if (currentImageIndex < images.length - 1) {
        preloadImage(images[currentImageIndex + 1].imageLink);
      }
    }
  }, [pro, idColor, currentImageIndex, color, images, preloadImage]);

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  const showTemporaryNotification = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
  };

  const handleSizeButtonClick = (size) => {
    setSelectedSize(size);
    setShowAlert(false);
  };

  const addProductToCart = () => {
    if (!pro || !selectedSize) {
      setShowAlert(true);
      return;
    }

    const quantidadeDisponivel = tamanhos[selectedSize];
    const quantidadeNoCarrinho = cartItems.reduce((total, cartItem) => {
      if (
        cartItem.id === pro.id &&
        cartItem.selectedSize === selectedSize &&
        cartItem.selectedColorId === idColor
      ) {
        return total + cartItem.quantity;
      }
      return total;
    }, 0);

    if (
      quantidadeDisponivel > 0 &&
      quantidadeNoCarrinho < quantidadeDisponivel
    ) {
      const newPrice = isDiscounted ? discountedPrice : price;

      const productWithSize = {
        ...pro,
        selectedSize: selectedSize,
        selectedColorId: idColor,
        selectedColorCode: selectedColor,
        newPrice: newPrice.toFixed(2),
      };

      dispatch(addItemToCart(cartItems, productWithSize));
      setShowAlert(false);
      showTemporaryNotification("Added to cart");
    } else {
      showTemporaryNotification("Maximum quantity reached for selected size");
    }
  };

  const handleColorButtonClick = (colorCode, newIdColor) => {
    setCurrentImageIndex(0);
    setIdColor(newIdColor);
    setSelectedColor(colorCode);
    setSelectedSize(null);
    setImageLoaded(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setImageLoaded(false);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setImageLoaded(false);
    }
  };

  if (!pro) {
    return <div>Produto não encontrado</div>;
  }

  return (
    <div className="product-page-container">
      {showNotification && <Notification message={notificationMessage} />}
      <>
        <div className="product-page-left">
          <div className="image-container">
            {currentImageIndex > 0 && (
              <button className="arrow-button prev" onClick={handlePrevImage}>
                &#8249; {/* Seta esquerda */}
              </button>
            )}
            {currentImageIndex < images.length - 1 && (
              <button className="arrow-button next" onClick={handleNextImage}>
                &#8250; {/* Seta direita */}
              </button>
            )}
            <img
              src={images[currentImageIndex].imageLink}
              alt={`${pro.name}`}
              style={{ opacity: imageLoaded ? 1 : 0 }}
              onLoad={() => setImageLoaded(true)}
            />
          </div>
        </div>
        <div className="product-page-right">
          <h1>{pro.name}</h1>
          {isDiscounted ? (
            <div className="discount-container">
              <span className="discounted-price">{formatCurrency(price)}€</span>
              <span>{formatCurrency(discountedPrice)}€</span>
              <span className="discount-label">{discount}% OFF</span>
            </div>
          ) : (
            <span>{formatCurrency(price)}€</span>
          )}

          <br />
          <span>Color: {color}</span>
          <br />
          <span>Sizes</span>
          <div className="tamanho-container">
            {tamanhos ? (
              sizesOrder.map((size) => {
                const quantidade = tamanhos[size];

                if (quantidade === -1) return null;

                return (
                  <button
                    key={size}
                    className={`button ${
                      selectedSize === size ? "selecionado" : ""
                    } ${quantidade === 0 ? "esgotado" : ""}`}
                    onClick={() => handleSizeButtonClick(size)}
                    disabled={quantidade === 0}
                  >
                    {size}
                    {quantidade === 0 && (
                      <svg>
                        <line x1="10%" y1="90%" x2="90%" y2="10%"></line>
                      </svg>
                    )}
                  </button>
                );
              })
            ) : (
              <div>Tamanhos não disponíveis</div>
            )}
          </div>

          {showAlert && (
            <div className="alertContainer">
              <span>Por favor, selecione um tamanho</span>
            </div>
          )}
          <SizeGuide product={pro} />
          <div className="color-container">
            {colors.map((colorOption, index) => (
              <button
                key={colorOption.color}
                className={`color-button ${
                  index === idColor ? "selecionado" : ""
                }`}
                style={{ backgroundColor: `${colorOption.colorCode}` }}
                onClick={() =>
                  handleColorButtonClick(colorOption.colorCode, index)
                }
              ></button>
            ))}
          </div>
          <br />
          <br />
          <Button
            buttonType={BUTTON_TYPE_CLASSES.inverted}
            onClick={addProductToCart}
          >
            Add to cart
          </Button>
        </div>
      </>
    </div>
  );
};

export default ProductPage;
