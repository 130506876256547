import { createAction } from "../../utils/reducer/reducer.utils";
import { CART_ACTION_TYPES } from "./cart.types";

const addCartItem = (cartItems, productToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.id === productToAdd.id &&
      cartItem.selectedSize === productToAdd.selectedSize &&
      cartItem.selectedColorId === productToAdd.selectedColorId
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.id === productToAdd.id &&
      cartItem.selectedSize === productToAdd.selectedSize &&
      cartItem.selectedColorId === productToAdd.selectedColorId
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

const removeCartItem = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.id === cartItemToRemove.id &&
      cartItem.selectedSize === cartItemToRemove.selectedSize &&
      cartItem.selectedColorId === cartItemToRemove.selectedColorId
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) =>
        cartItem.id !== cartItemToRemove.id ||
        cartItem.selectedSize !== cartItemToRemove.selectedSize ||
        cartItem.selectedColorId !== cartItemToRemove.selectedColorId
    );
  }

  return cartItems.map((cartItem) =>
    cartItem.id === cartItemToRemove.id &&
    cartItem.selectedSize === cartItemToRemove.selectedSize &&
    cartItem.selectedColorId === cartItemToRemove.selectedColorId
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

const clearCartItem = (cartItems, cartItemToClear) =>
  cartItems.filter(
    (cartItem) =>
      cartItem.id !== cartItemToClear.id ||
      cartItem.selectedSize !== cartItemToClear.selectedSize ||
      cartItem.selectedColorCode !== cartItemToClear.selectedColorCode
  );

const removeZeroPriceItems = (cartItems) =>
  cartItems.filter((item) => item.newPrice !== "0.00");

//handle para controlar brindes e a sua existencia no cart
const handleCartTotalAndZeroPriceItems = (cartItems) => {
  const cartTotal = cartItems.reduce(
    (total, cartItem) =>
      total + parseFloat(cartItem.newPrice) * cartItem.quantity,
    0
  );

  return cartTotal < 40 ? removeZeroPriceItems(cartItems) : cartItems;
};

export const setIsCartOpen = (Boolean) =>
  createAction(CART_ACTION_TYPES.SET_IS_CART_OPEN, Boolean);

export const addItemToCart = (cartItems, productToAdd) => {
  const newCartItems = addCartItem(cartItems, productToAdd);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const newCartItems = removeCartItem(cartItems, cartItemToRemove);
  const updatedCartItems = handleCartTotalAndZeroPriceItems(newCartItems);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, updatedCartItems);
};

export const clearItemFromCart = (cartItems, cartItemToClear) => {
  const newCartItems = clearCartItem(cartItems, cartItemToClear);
  const updatedCartItems = handleCartTotalAndZeroPriceItems(newCartItems);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, updatedCartItems);
};

export const clearCart = () => createAction(CART_ACTION_TYPES.CLEAR_CART);
