import { Fragment } from "react";
import { useSelector } from "react-redux";

import {
  selectCategoriesMap,
  selectCategoriesIsLoading,
} from "../../store/categories/category.selector";

import CategoryPreview from "../../components/category-preview/category-preview.component";
import Spinner from "../../components/spinner/spinner.component";

import "./categories-preview.styles.scss";

const CategoriesPreview = () => {
  const categoriesMap = useSelector(selectCategoriesMap);
  const isLoading = useSelector(selectCategoriesIsLoading);

  // Filtrar as categorias que começam com "DROP"
  const filteredCategoriesMap = Object.entries(categoriesMap).reduce(
    (filteredMap, [title, products]) => {
      if (title.endsWith("drop")) {
        filteredMap[title] = products;
      }
      return filteredMap;
    },
    {}
  );

  return (
    <Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        Object.keys(filteredCategoriesMap).map((title) => {
          const products = filteredCategoriesMap[title];
          return (
            <CategoryPreview key={title} title={title} products={products} />
          );
        })
      )}
    </Fragment>
  );
};

export default CategoriesPreview;
