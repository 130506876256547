import React from "react";

import { Link } from "react-router-dom";
import { ButtonSuccess } from "../checkout/stripe-checkout/success.styles";
import { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";

import { PageNotFoundConatiner } from "./PageNotFound.styles";

const PageNotFound = () => {
  return (
    <PageNotFoundConatiner>
      <h1>404 PAGE NOT FOUND</h1>
      <p>We can't seem to find the page that you were looking for.</p>
      <div>
        <Link to="/shop">
          <ButtonSuccess buttonType={BUTTON_TYPE_CLASSES.base}>
            Continue Shopping
          </ButtonSuccess>
        </Link>
      </div>
    </PageNotFoundConatiner>
  );
};

export default PageNotFound;
